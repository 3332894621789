
import {computed, defineComponent, reactive, ref, h, createVNode} from 'vue';
import ForEditor from '@/components/for-editor';
import {useStore} from "vuex";
import {message, Modal, notification} from "ant-design-vue";
import {useRoute} from "vue-router";
import {
  create_address_by_wechat, create_bank_account_by_wechat,
  create_beneficiary_by_wechat,
  create_next_of_kin_by_wechat,
  create_person_reference_number_by_wechat,
  get_wechat_update_record_status, query_adviser_list,
  update_adviser_by_wechat,
  update_bank_account_by_wechat,
  update_beneficiary_by_wechat,
  create_social_insurance,
  update_next_of_kin_by_wechat,
  update_person_reference_number_by_wechat, check_payslip_bulk_progress, create_resume, query_resume_list, update_resume
} from '@/api/cerp-model';
import modalBox from "@/components/form-modal/modal-tools";
import {query_bank_manage_list} from '@/api/baseinfo-model';
import PageModel4 from '@/components/page-model4/index.vue';
import bus from '@/utils/bus';
import {useForm} from 'ant-design-vue/lib/form';
import {useI18n} from 'vue-i18n';
import {event_obj} from "@/components/page-model4/cerp/cerp-personreferencenumber-config";
import {query_all_nsfile_list} from '@/api/nsbcs-model';
import {ExclamationCircleOutlined, DeleteOutlined} from '@ant-design/icons-vue';
import {getMonth} from "@/utils/function";

export default defineComponent({
  components: {
    ForEditor,
    DeleteOutlined,
    ExclamationCircleOutlined,
    PageModel4,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const {t} = useI18n();
    const current_org = store.getters['user/current_org'];
    const adviser = computed(() => store.getters[`user/adviser`]);
    const data_list: any = reactive({});
    const file_list1: any = ref([]);
    const state: any = reactive({
      introduction: '',
      skill: '',
      datas: [],
      educations: [],
      experiences: [],
      appendix_ids: [],
      resume_id: null,
    });
    let is_create1: any = true;

    query_adviser_list({
      org_id: current_org.id,
      ids: [adviser.value.adviser_id],
    }).then((res: any) => {
      if (res && res.data && res.data.length > 0) {
        Object.assign(data_list, res.data[0]);
      }
    })
    const search_resume = () => {
      query_resume_list({
        org_id: current_org.id,
        adviser_id: adviser.value.adviser_id,
      }).then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          Object.assign(state, res.data[0]);
          state.resume_id = res.data[0].id;
          state.educations = res.data[0].educations;
          state.experiences = res.data[0].experiences;
          state.datas = res.data[0].datas;
          if (res.data[0].appendix_ids) {
            state.appendix_ids = res.data[0].appendix_ids.split(',');
          } else {
            state.appendix_ids = [];
          }
          queryFileList();
          is_create1 = false;
        }
        console.log('state', state)
      })
    }
    const queryFileList = () => {
      if (state.appendix_ids && state.appendix_ids.length > 0) {
        query_all_nsfile_list({
          fileids: state.appendix_ids,
        }).then((res: any) => {
          if (res && res.data && res.data.length > 0) {
            file_list1.value.length = 0;
            res.data.map((item: any) => {
              file_list1.value.push({
                file_id: item.id,
                file_url: item.fileurl,
                file_name: item.name,
              });
            })
          }
        })
      }
    }
    const handleUploadFile = () => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: '上传附件',
        group: '简历附件',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            state.appendix_ids.push(...payload);
            queryFileList();
            resolve(null);
            handlerOk();
          });
        },
      });
      modalBox(editModal);
    }

    const handlerOk = () => {
      if (is_create1) {
        create_resume({
          org_id: current_org.id,
          introduction: state.introduction,
          skill: state.skill,
          appendix_ids: state.appendix_ids,
          datas: JSON.stringify(state.datas),
          educations: JSON.stringify(state.educations),
          experiences: JSON.stringify(state.experiences),
          adviser_id: adviser.value.adviser_id,
        }).then((res: any) => {
          search_resume();
        })
      } else {
        update_resume({
          org_id: current_org.id,
          introduction: state.introduction,
          skill: state.skill,
          appendix_ids: state.appendix_ids,
          datas: JSON.stringify(state.datas),
          educations: JSON.stringify(state.educations),
          experiences: JSON.stringify(state.experiences),
          adviser_id: adviser.value.adviser_id,
          resume_id: state.resume_id,
        }).then((res: any) => {
          search_resume();
        })
      }
    }
    const openUrl = (record: any) => {
      if (record.file_url) {
        const url =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + record.file_url;
        window.open(url, '_blank');
      } else {
        notification.error({
          message: '提示',
          description: '无对应附件',
        });
      }
    }
    const delete_file_list = (record: any) => {
      Modal.confirm({
        title: '是否删除?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            state.appendix_ids = [];
            file_list1.value.map((item: any, index: any) => {
              if (record.file_id == item.file_id) {
                file_list1.value.splice(index, 1);
              }
            })
            file_list1.value.map((item: any) => {
              state.appendix_ids.push(item.file_id);
            })
            resolve(null);
            handlerOk();
          })
        },
        onCancel() {
        },
      })
    }
    search_resume();
    const handleAddEducations = () => {
      const editModal = reactive({
        visible: true,
        title: '添加教育背景信息',
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'rangepicker',
              name: 'start_end_date',
              label: '起止时间',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '起止时间',
              size: 'small',
            },
            {
              type: 'input',
              name: 'school',
              label: '学校',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写学校',
              size: 'small',
            },
            {
              type: 'input',
              name: 'major',
              label: '专业',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写专业',
              size: 'small',
            },
            {
              type: 'input',
              name: 'education',
              label: '学历',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写学历',
              size: 'small',
            },
          ],
          rules: {},
          model: reactive({
            education: null,
            major: null,
            school: null,
            start_end_date: null,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            state.educations.push({...payload, time: payload.start_end_date[0] + '-' + payload.start_end_date[1]})
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }
    const handleDelEducations = (index: any) => {
      Modal.confirm({
        title: '是否删除?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            state.educations.splice(index, 1);
            resolve(null);
            handlerOk();
          })
        },
        onCancel() {
        },
      })
    }
    const handleAddExperiences = () => {
      const editModal = reactive({
        visible: true,
        title: '工作/培训经历',
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'rangepicker',
              name: 'start_end_date',
              label: '时间',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '时间',
              size: 'small',
            },
            {
              type: 'input',
              name: 'company',
              label: '公司',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写公司',
              size: 'small',
            },
            {
              type: 'input',
              name: 'post',
              label: '职务',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写职务',
              size: 'small',
            },
            {
              type: 'textarea',
              name: 'note',
              label: '工作描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              minRows: 4,
              defaultValue: '',
              placeholder: '请填写工作描述',
            },
          ],
          rules: {},
          model: reactive({
            note: null,
            post: null,
            company: null,
            start_end_date: null,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            state.experiences.push({...payload, time: payload.start_end_date[0] + '-' + payload.start_end_date[1]})
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }
    const handleDelExperiences = (index: any) => {
      Modal.confirm({
        title: '是否删除?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            state.experiences.splice(index, 1);
            resolve(null);
            handlerOk();
          })
        },
        onCancel() {
        },
      })
    }
    const handleAddData = () => {
      const editModal = reactive({
        visible: true,
        title: '编辑额外信息',
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'attributetable',
              name: 'datas',
              label: '额外信息',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写额外信息',
            },
          ],
          rules: {},
          model: reactive({
            datas: state.datas,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            state.datas = payload.datas;
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }
    const handleAddSkill = () => {
      const editModal = reactive({
        visible: true,
        title: '编辑技能证书信息',
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'textarea',
              name: 'skill',
              label: '技能证书信息',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              minRows: 4,
              defaultValue: '',
              placeholder: '请填写技能证书信息',
            },
          ],
          rules: {},
          model: reactive({
            skill: state.skill,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            state.skill = payload.skill;
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }

    const handleAddIntroduction = () => {
      const editModal = reactive({
        visible: true,
        title: '编辑自我评价',
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'textarea',
              name: 'introduction',
              label: '自我评价',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              minRows: 4,
              defaultValue: '',
              placeholder: '请填写自我评价',
            },
          ],
          rules: {},
          model: reactive({
            introduction: state.introduction,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            state.introduction = payload.introduction;
            resolve(null);
            handlerOk();
            editModal.loading = false;
          });
        },
      });
      modalBox(editModal);
    }
    return {
      t,
      state,
      data_list,
      handleAddEducations,
      handleDelEducations,
      handleDelExperiences,
      handleAddExperiences,
      handleAddSkill,
      handleAddIntroduction,
      handleAddData,
      openUrl,
      file_list1,
      handlerOk,
      handleUploadFile,
      delete_file_list,
    };
  },
});
